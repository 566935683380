import { enquiry } from '@store/index'
import { useSetRecoilState, useResetRecoilState, useRecoilValue } from 'recoil'
import { useCallback } from 'react'
import { enquiryDataLayer } from '@plugin/google/gtm/modules/property'

export default function useEnquiry() {

  const state = useRecoilValue(enquiry)
  const setEnquiryState = useSetRecoilState(enquiry)
  const resetEnquiryState = useResetRecoilState(enquiry)

  // 房源咨询
  const showHouseEnquiry = useCallback(({ house, agent, sendAllAgent = false, signupSource = 'agent_enquiry', spec, enquiryType }) => {
    return event => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
      enquiryDataLayer.show()
      setEnquiryState(d => ({ ...d, show: true, isHouse: true, sendAllAgent, house, agent, signupSource, spec, enquiryType }))
    }
  }, [setEnquiryState])
  // 更新咨询的中介
  const changeHouseEnquiryAgent = useCallback((agent) => {
    setEnquiryState(d => ({ ...d, agent }))
  }, [setEnquiryState])

  // 中介咨询
  const showAgentEnquiry = useCallback(({ agent, signupSource, spec }) => {
    return event => {
      event.preventDefault()
      event.stopPropagation()
      enquiryDataLayer.show()
      setEnquiryState(d => ({ ...d, show: true, isAgent: true, agent, signupSource, spec }))
    }
  }, [setEnquiryState])

  // 中介公司咨询
  const showOfficeEnquiry = useCallback(({ office, signupSource, spec }) => {
    return event => {
      event.preventDefault()
      event.stopPropagation()
      setEnquiryState(d => ({ ...d, show: true, isOffice: true, office, signupSource, spec }))
    }
  }, [setEnquiryState])

  // builder咨询
  const showBuilderEnquiry = useCallback(({ builder, signupSource, spec }) => {
    return event => {
      event.preventDefault()
      event.stopPropagation()
      setEnquiryState(d => ({ ...d, show: true, isBuilder: true, builder, signupSource, spec }))
    }
  }, [setEnquiryState])

  // display home咨询
  const showDisplayHomeEnquiry = useCallback(({ displayHome, builder, signupSource, spec }) => {
    return event => {
      event.preventDefault()
      event.stopPropagation()
      setEnquiryState(d => ({ ...d, show: true, isDisplayHome: true, displayHome, builder, signupSource, spec }))
    }
  }, [setEnquiryState])

  // 隐藏咨询
  const hideEnquiry = useCallback(() => {
    resetEnquiryState()
  }, [resetEnquiryState])

  // 咨询成功
  const enquirySuccess = useCallback(formData => {
    enquiryDataLayer.success()
    setEnquiryState(d => ({ ...d, formData, success: true }))
  }, [setEnquiryState])

  // 是否注册成功
  const changeRegistered = useCallback((registered) => {
    setEnquiryState(d => ({ ...d, registered }))
  }, [setEnquiryState])

  return {
    ...state,
    showHouseEnquiry,
    changeHouseEnquiryAgent,
    showAgentEnquiry,
    showOfficeEnquiry,
    showBuilderEnquiry,
    showDisplayHomeEnquiry,
    hideEnquiry,
    enquirySuccess,
    changeRegistered
  }
}