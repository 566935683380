import { useEffect, useState } from 'react';

export default function useFixedTop({
  elementRef,
  elementHeight
}) {
  const [top, setTop] = useState(0)

  useEffect(() => {
    function resizeHandler() {
      // viewport height
      const viewportHeight = window.visualViewport?.height ?? 0
      // element height
      let elHeight = elementHeight
      if (elementRef && elementRef.current) {
        elHeight = elementRef.current.offsetHeight
      }
      // math
      setTop(viewportHeight + window.scrollY - elHeight)
    }

    // run first time to initialize 
    resizeHandler()

    // subscribe to events which affect scroll, or viewport position
    window.visualViewport?.addEventListener('resize', resizeHandler)
    window.visualViewport?.addEventListener('scroll', resizeHandler)
    window?.addEventListener('touchmove', resizeHandler)

    // unsubscribe
    return () => {
      window.visualViewport?.removeEventListener('resize', resizeHandler)
      window.visualViewport?.removeEventListener('scroll', resizeHandler)
      window?.removeEventListener('touchmove', resizeHandler)
    };
  }, [elementRef, elementHeight]);

  return {
    top
  }
}