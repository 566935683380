'use client'

import Avatar from '@component/avatar'
import ImageBgLoading from '@component/imageBgLoading'
import { get, pick } from 'lodash-es'
import IconCopy from '@icon/house/copy.svg'
import { useEffect, useMemo, useRef } from 'react'
import ClipboardJS from 'clipboard'
import classNames from 'classnames'
import { useBoolean } from 'react-use'
import Tooltip from '@component/tooltip'
import { blockNumber } from '@utils'
import { enquiryDataLayer } from '@plugin/google/gtm/modules/property'

export default function Agent({
  data,
  house
}) {

  const [showPhone, togglePhone] = useBoolean(false)
  const [copySuccess, toggleCopySuccess] = useBoolean(false)
  const copyRef = useRef(null)
  const timer = useRef(null)

  useEffect(() => {
    if (!copyRef.current || !showPhone) return

    const clipboard = new ClipboardJS(copyRef.current, {
      text: () => data.phoneNumber
    })
    clipboard.on('success', () => {
      toggleCopySuccess(true)
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        toggleCopySuccess(false)
      }, 3000)
    })

    return () => {
      clipboard.destroy()
    }
  }, [data, copyRef, timer, showPhone, toggleCopySuccess])

  const office = useMemo(() => {
    if (house) {
      if (house.office) {
        return pick(house.office, ['icon', 'name', 'bgColor'])
      }
    } else if (data.office) {
      return pick(data.office, ['icon', 'name', 'bgColor'])
    }
  }, [data, house])

  function copyHandle(event) {
    event.preventDefault()
  }
  function handleDisplayPhone() {
    togglePhone(true)
    enquiryDataLayer.phoneClick()
  }

  return (
    <div className='flex'>
      <Avatar src={data.icon} width={96} height={96} alt={data.name} className='rounded-sm flex-shrink-0 !w-64 !h-64 sm:!w-96 sm:!h-96'></Avatar>
      <div className='flex-1 mx-16 min-w-0 flex flex-col items-start justify-between'>
        <OfficeLogo data={office} className='sm:hidden !p-0' imgClassName='w-140 h-20' />
        <div className='text-font text-lg font-medium'>{data.name}</div>
        <div className='text-sm text-neutral-5 hidden sm:block'>{data.jobTitle}</div>
        {
          data.phoneNumber && (
            <Tooltip zIndex={201} active={copySuccess} disabled right content={<div className='text-secondary text-md px-10 py-2'>Copied!</div>}>
              <a href={`tel:${data.phoneNumber}`} type='button' className='button-primary-out h-32' onClick={handleDisplayPhone} data-noprogress>
                <i className='icon icon-phone text-primary'></i>
                <span className='ml-8'>{showPhone ? data.phoneNumber : blockNumber(data.phoneNumber)}</span>
                {
                  showPhone && (
                    <>
                      <i className='w-1 h-14 bg-primary ml-8'></i>
                      <button className='cursor-pointer h-full px-8 -mr-8' onClick={copyHandle} ref={copyRef}>
                        <IconCopy width={14} height={14} fill='var(--color-theme)' className='block'></IconCopy>
                      </button>
                    </>
                  )
                }
              </a>
            </Tooltip>
          )
        }
      </div>
      <OfficeLogo data={office} className='hidden sm:block' />
    </div>
  )
}

function OfficeLogo({
  data,
  className,
  imgClassName
}) {
  return (
    get(data, 'icon') && (
      <div style={{ backgroundColor: data.bgColor }} className={classNames('sm:self-center p-4 rounded-sm', className)}>
        <ImageBgLoading src={get(data, 'icon')} width={200} height={36} alt={get(data, 'name')} className={classNames('object-contain object-center block', imgClassName)}></ImageBgLoading>
      </div>
    )
  )
}