'use client'

import httpClient from '@apiClient'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import Form from '@component/form'
import FormButton from '@component/form/button'
import FormCheckbox, { FormCheckboxGroup } from '@component/form/checkbox'
import FormInput from '@component/form/input'
import FormSearchInput from '@component/form/searchInput'
import FormSelect from '@component/form/select'
import { HOUSE_TYPE_ID } from '@config'
import useConfirm from '@hooks/useConfirm'
import useProgressRouter from '@hooks/useProgressRouter'
import IconClap from '@icon/clap.svg'
import { formDataLayer } from '@plugin/google/gtm/modules/form'
import { prospectUser } from '@plugin/login'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import { URLS, getRouterHref, houseList } from '@router'
import { houseSearchType } from '@routerConfig'
import { global } from '@store/index'
import { callNative } from '@utils'
import { assign, each, filter, find, get, map, size, some } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

export default function FormContent({
  data
}) {

  const form = get(data, 'formData.form')
  const field = get(data, 'formData.form.field', [])

  // 默认数据
  const [defaultData, rules] = useMemo(() => {
    const data = {}
    const rules = {}
    each(field, item => {
      if (item.alias) {
        // 默认值
        const isCheckbox = item.type === 'checkbox'
        const isOption = item.type === 'option'
        const isReceiveEmails = item.type === 'receiveEmails'
        data[item.alias] = isCheckbox ? [] : isOption ? 0 : isReceiveEmails ? 1 : ''
        if (size(item.options) > 0) {
          some(item.options, option => {
            if (option.default) {
              if (isCheckbox) {
                data[item.alias].push(option.value)
              } else {
                data[item.alias] = option.value
                return true
              }
            }
          })
        }

        let _rule = {}
        // 规则
        if (item.required) {
          assign(_rule, {
            required: true,
            validator: function (_, value) {
              return Boolean(value) || value === 0
            },
            message: '',
            trigger: 'blur'
          })
        }
        if (['email', 'receiveEmails'].includes(item.type)) {
          assign(_rule, {
            type: 'email',
            message: 'Please enter a valid email address',
            trigger: 'blur',
            validator: undefined
          })
        }
        if (item.type === 'address') {
          assign(_rule, {
            required: true,
            validator: function (_, value) {
              return Boolean(value)
            },
            message: 'Please select an address',
            trigger: 'blur'
          })
        }
        if (item.type === 'suburb') {
          assign(_rule, {
            required: true,
            validator: function (_, value) {
              return Boolean(value)
            },
            message: 'Please choose one suburb',
            trigger: 'blur'
          })
        }
        rules[item.alias] = _rule
      }
    })
    return [
      data,
      rules
    ]
  }, [field])
  // 表单数据
  const [formData, setFormData] = useState(defaultData)

  // 提交表单
  const { openConfirm } = useConfirm()
  const { pushRouter } = useProgressRouter()
  const { isFromApp } = useRecoilValue(global)
  const poster = useCallback(async d => {

    let email = null
    let firstName = null
    let receive = false
    const userData = []
    const emailKey = get(find(field, item => item.type === 'email'), 'alias', null)
    const firstNameKey = get(find(field, item => item.type === 'name'), 'alias', null)
    const receiveKey = get(find(field, item => item.type === 'receiveEmails'), 'alias', null)
    each(d, (val, key) => {
      if (key === emailKey) email = val
      if (key === receiveKey) receive = val
      if (key === firstNameKey) firstName = val
      userData.push({ [key]: val })
    })

    if (email) {
      await prospectUser({ email, isReceiveBizEmail: receive ? 1 : 0, registeredCheck: 0, signupSource: form.signupSource, firstName })
    }
    await httpClient.put(`online-form/${data.slug}`, { userData })

    const houseRoute = getHouseRoute()
    houseRoute.pushNewZealand()
    await openConfirm({
      content: (
        <div>
          <IconClap></IconClap>
          <div className='text-3xl mt-10'>Thanks for completing this form</div>
          <div className='text-base mt-10'>Start your search from thousands of properties on OneRoof.</div>
          {
            isFromApp
              ? (
                <div className='button-primary w-full mt-20' onClick={() => callNative('tohome')}>View properties</div>
              )
              : (
                <DisablePrefetchLink href={houseList(houseSearchType.residential, houseRoute.toSlug())} target='_blank' className='button-primary w-full mt-20'>View properties</DisablePrefetchLink>
              )
          }
        </div>
      ),
      contentClassName: '!px-16 !pb-20',
      useButton: false
    })

    pushRouter(getRouterHref(URLS.homepage))
  }, [data, openConfirm, pushRouter, isFromApp, form, field])

  const handleSubmit = useCallback(() => {
    formDataLayer.submitClick()
  }, [])

  return (
    <div className='w-[732px] max-w-full mx-auto px-16 py-20 sm:py-40'>
      <div className='text-2xl text-font'>{form.title}</div>
      <div className='text-base text-neutral-5 mt-10'>{form.subTitle}</div>
      <Form data={formData} rules={rules} onChange={setFormData} poster={poster} className='mt-20'>
        {
          map(field, (item, idx) => {
            switch (item.type) {
              case 'input':
              case 'email':
                return <FormInput key={idx} prop={item.alias} label={item.title} required={item.required}></FormInput>
              case 'address':
                return (
                  <FormSearchInput
                    key={idx}
                    prop={item.alias}
                    label={item.title}
                    required={item.required}
                    fetcher={fetchAddress}
                  />
                )
              case 'suburb':
                return (
                  <FormSearchInput
                    key={idx}
                    prop={item.alias}
                    label={item.title}
                    required={item.required}
                    fetcher={fetchSuburb}
                  />
                )
              case 'select':
                return <FormSelect key={idx} prop={item.alias} label={item.title} required={item.required} data={item.options}></FormSelect>
              case 'checkbox':
                return <FormCheckboxGroup key={idx} prop={item.alias} label={item.title} required={item.required} data={item.options} multiple></FormCheckboxGroup>
              case 'option':
              case 'receiveEmails':
                return (
                  <FormCheckbox key={idx} prop={item.alias} trueValue={1} falseValse={0} className='!mt-0'>
                    <span dangerouslySetInnerHTML={{ __html: item.title || '' }} className='child-[a]:underline'></span>
                  </FormCheckbox>
                )
              case 'policy':
                return <div key={idx} className='text-sm mt-20 child-[a]:underline' dangerouslySetInnerHTML={{ __html: item.title || '' }}></div>
            }
          })
        }
        <FormButton onClick={handleSubmit}>Submit</FormButton>
      </Form>
    </div>
  )
}

async function fetchAddress(query) {
  const { properties } = await httpClient.get('address/search', { isMix: 1, key: query, typeId: HOUSE_TYPE_ID.estimate })

  if (size(properties)) {
    return map(properties, item => ({ label: item.pureLabel, value: item.id }))
  }

  return []
}

async function fetchSuburb(query) {
  const { locations } = await httpClient.get('address/search', { isMix: 1, key: query, typeId: HOUSE_TYPE_ID.residential })

  if (size(locations)) {
    return map(filter(locations, item => item.level === 'suburb')
      , item => ({ label: item.pureLabel, value: item.id }))
  }
  return []
}