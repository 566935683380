'use client'

import useEnquiry from '@hooks/useEnquiry'
import useFetch from '@hooks/useFetch'
import { getHouseId, isRentData, isVillageData } from '@utils/house'
import { get } from 'lodash-es'
import { useMemo } from 'react'
import FormContent, { FormSkeleton } from './content'

export default function EnquiryForm({
}) {

  const enquiryState = useEnquiry()
  const params = useMemo(() => {

    const {
      house,
      agent,
      office,
      suburb,
      builder,
      displayHome,
      isHouse,
      isAgent,
      isOffice,
      isSuburb,
      isBuilder,
      isDisplayHome,
      enquiryType
    } = enquiryState

    let type = enquiryType
    let id
    if (isHouse) {
      if (!type) {
        // if (isProjectData(house)) {
        //   type = 'property'
        // } else 
        if (isVillageData(house)) {
          type = 'village'
        } else if (isRentData(house)) {
          type = 'rental'
        } else {
          type = 'house'
        }
      }
      id = getHouseId(house)
    } else if (isAgent) {
      type = 'agent'
      id = get(agent, 'id')
    } else if (isOffice) {
      type = 'agent'
      id = get(office, 'id')
    } else if (isSuburb) {
      type = 'suburb'
      id = get(suburb, 'id')
    } else if (isBuilder || isDisplayHome) {
      type = 'agent'
      id = get(builder || displayHome, 'id')
    }

    // type有这几种类型
    // house|agent|rental|property|suburb|village|office
    return {
      type,
      id
    }
  }, [enquiryState])
  const { data: enquirySpecs, isLoading } = useFetch({ url: 'enum/enquiry-specs', params })

  return (
    <div className='mt-20'>
      {
        isLoading
          ? <FormSkeleton></FormSkeleton>
          : <FormContent specs={enquirySpecs} type={params.type}></FormContent>
      }
    </div >
  )
}