'use client'

import { SkeletonCard } from '@component/card/listings/standard';
import { global } from '@store/index';
import classNames from 'classnames';
import { map, range } from 'lodash-es';
import { useRecoilValue } from 'recoil';

export default function CarouselListing({
  showTitle = true,
  slideNum = 4,
  className = '',
}) {
  const { mobile } = useRecoilValue(global);
  const slides = mobile ? 1 : slideNum;

  return (
    <div className={classNames('skeleton-wrap', className)}>
      {showTitle && <span className='h-20 w-1/6 mb-20'></span>}
      <div className='grid grid-cols-1 md:grid-cols-4 gap-16'>
        {
          map(range(0, slides), idx => (
            <SkeletonCard key={idx}></SkeletonCard>
          ))
        }
      </div>
    </div>
  )
}