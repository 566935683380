import { HOUSE_TYPE_ID } from '@config'
import { getTime, joinStr, toNumber } from '@utils'
import { assign, find, first, get, replace } from 'lodash-es'

/**
 * 判断是否property
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isPropertyData(data) {
  return !!get(data, 'propertyId')
}

/**
 * 判断是否sale
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isSaleData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID.residential
}

/**
 * 判断是否rural
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isRuralData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID.rural
}

/**
 * 判断是否rural for sale
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isRuralForSaleData(data) {
  return isRuralData(data) && data.status === 1
}

/**
 * 判断是否village
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isVillageData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID.village
}

/**
 * 判断是否rent
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isRentData(data) {
  return data && [HOUSE_TYPE_ID.rent, HOUSE_TYPE_ID['personal-rental']].includes(toNumber(get(data, 'typeId')))
}

/**
 * 判断是否sold
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isSoldData(data) {
  return data && (toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID.sold || get(data, 'status') === 2)
}

/**
 * 判断是否estimate
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isEstimateData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID.estimate
}

/**
 * 判断是否Rateable value
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isRVData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID.rv
}

/**
 * 判断是否withdraw
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isWithdrawData(data) {
  return toNumber(get(data, 'status')) === 3
}

/**
 * 判断是否下线
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isOfflineData(data) {
  return isSoldData(data) || isWithdrawData(data)
}

/**
* is project
* @param {object} data 房源数据
* @returns boolean
*/
export function isProjectData(data) {
  return get(data, 'isProject', false)
}

/**
* is childen
* @param {object} data 房源数据
* @returns boolean
*/
export function isChildenData(data) {
  return !!get(data, 'projectOfHouse')
}

/**
* is apartment
* @param {object} data 房源数据
* @returns boolean
*/
export function isApartmentData(data) {
  return isChildenData(data) && !get(data, 'isLandHome')
}

/**
* is landhome
* @param {object} data 房源数据
* @returns boolean
*/
export function isLandHomeData(data) {
  return isChildenData(data) && get(data, 'isLandHome')
}

/**
* is newhome
* @param {object} data 房源数据
* @returns boolean
*/
export function isNewhomeData(data) {
  return !!get(data, 'projectOfHouse')
}

/**
* is commercial for sale
* @param {object} data 房源数据
* @returns boolean
*/
export function isCommercialForSaleData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID['commercial-property-for-sale']
}

/**
* is commercial for lease
* @param {object} data 房源数据
* @returns boolean
*/
export function isCommercialForLeaseData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID['commercial-property-for-lease']
}

/**
* is businesses for sale
* @param {object} data 房源数据
* @returns boolean
*/
export function isBusinessesForSaleData(data) {
  return toNumber(get(data, 'typeId')) === HOUSE_TYPE_ID['businesses-for-sale']
}

/**
* is commercial
* @param {object} data 房源数据
* @returns boolean
*/
export function isCommercialData(data) {
  return isCommercialForSaleData(data) || isCommercialForLeaseData(data) || isBusinessesForSaleData(data)
}


/**
 * 获取房源id
 * 房源详情houseId = data.houseId
 * 其他列表，比如carousel、houses/list； houseId = data.id
 * @param {object} data 房源数据
 * @returns houseId
 */
export function getHouseId(data) {
  return get(data, 'houseId') || get(data, 'id')
}

/**
 * 是否显示Favourite button
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isShowFavoriteButton(data) {
  return data.houseId && !isOfflineData(data)
}

/**
 * 是否显示track button
 * @param {object} data 房源数据
 * @returns boolean
 */
export function isShowTrackButton(data) {
  return !isShowFavoriteButton(data) && isPropertyData(data)
}

/**
 * 获取房源label
 * @param {object} data 房源数据
 * @returns label
 */
export function getSuburbLabel(data) {
  return replace(get(data, 'label') || get(data, 'pureLabel'), /<\/?mark>/g, '')
}

/**
 * 获取房源sold label
 * @param {object} data 房源数据
 * @returns label
 */
export function getSoldLabel(data) {
  const soldInfo = get(data, 'soldInfo', null)
  return soldInfo
    ? (
      soldInfo.soldPrice
        ? `Sale price: ${soldInfo.soldPrice}`
        : soldInfo.soldMethodLabel
          ? `Sale method: ${soldInfo.soldMethodLabel}`
          : joinStr(['Sale date:', getTime(soldInfo.soldDate, 'MMM YYYY')])
    )
    : null
}

export function getHouseEnquiryParams(httpClient, spec) {
  return async (house, user, isReceiveBizEmail = true) => {
    let type
    if (isVillageData(house)) {
      type = 'village'
    } else if (isRentData(house)) {
      type = 'rental'
    } else {
      type = 'house'
    }
    let defalutSpecs = spec
    if (!defalutSpecs) {
      const enquirySpecs = await httpClient.get('enum/enquiry-specs', { type, id: getHouseId(house) })
      defalutSpecs = find(enquirySpecs, item => item.value === 'default') || first(enquirySpecs)
    }

    return assign(
      {
        type,
        name: get(user, 'firstName', ''),
        email: get(user, 'email', ''),
        isReceiveBizEmail: isReceiveBizEmail ? 1 : 0,
        id: getHouseId(house),
        propertyAddress: get(house, 'address', ''),
        spec: get(defalutSpecs, 'value'),
        body: get(defalutSpecs, 'default', ''),
      }
    )
  }
}

/**
 * 获取房源auction label
 * @param {*} data house
 */
export function getHouseAuctionLabel(data) {
  const auctionStart = get(data, 'auction.start')
  const auctionLabel = auctionStart ? getTime(auctionStart, 'D MMM YYYY') : undefined
  return auctionLabel
}

/**
 * 获取房源auction label
 * @param {*} data house
 */
export function getHouseOpenhomeLabel(data) {
  const openhomeStart = get(data, 'openHomes.times.0.start')
  const openhomeLabel = openhomeStart ? getTime(openhomeStart, 'D MMM YYYY') : undefined
  return openhomeLabel
}