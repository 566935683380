'use client'

import { HOUSE_LIST_SHOW_MAP_KEY, RESIDENTIAL_TABS } from '@config'
import { RESIDENTIAL_ALIAS, isSoldSearchType, isSuburbSearchType } from '@homeSearch/modules/filter/config/content'
import SearchPopup from '@homeSearch/modules/searchPopup'
import useProgressRouter from '@hooks/useProgressRouter'
import { homeDataLayer } from '@plugin/google/gtm/modules/home'
import { RecentViewManager } from '@plugin/recentView'
import { BuyBySuburb } from '@plugin/recentView/entity/buyBySuburb'
import { EstimateByArea } from '@plugin/recentView/entity/estimateByArea'
import { RentBySuburb } from '@plugin/recentView/entity/rentBySuburb'
import { SoldBySuburb } from '@plugin/recentView/entity/soldBySuburb'
import getEstimateRoute from '@plugin/searchRoute/modules/estimate'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import { cacheRecentActivity } from '@plugin/user'
import { URLS, estimateMap, getRouterHref, houseList } from '@router'
import { first, get, isEqual } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocalStorage } from 'react-use'
import useLayoutContext from '../layout/context'
import { SearchWrapNew } from '../modules/base'
import useHomeSearchContext, { cacheSuggestedSuburbs } from '../modules/context'
import { autoFetch, fetchBuy, fetchRent } from '../modules/fetch'
import HomeSearchFilter from '../modules/filter'
import { RESIDENTIAL_FILTER } from '../modules/filter/config'
import { HomeSearchInputNew } from '../modules/input'
import Location from '../modules/location'
import HomeSearchPopup from '../modules/popup'

export default function ResidentialContent({
  title = 'Start your property search'
}) {

  /**
   * 这里在input和filter外面的单独处理了 suburbs和surrounding
   * input在点击search按钮的时候，同步suburbs和surrounding到filter
   * filter在点击关闭的时候，同步数据到input
   */

  const [type, setType] = useState(first(RESIDENTIAL_TABS))
  const filterSearchType = useMemo(() => {
    if (type.value === RESIDENTIAL_TABS[0].value) {
      return RESIDENTIAL_FILTER.searchTypes[0]
    } else if (type.value === RESIDENTIAL_TABS[1].value) {
      return RESIDENTIAL_FILTER.searchTypes[1]
    } else if (type.value === RESIDENTIAL_TABS[2].value) {
      return RESIDENTIAL_FILTER.searchTypes[2]
    }
  }, [type])
  const { suburbs, surrounding, displayFilter, showFilter, hideFilter, changeSuburbs, toggleSurrounding } = useLayoutContext()
  const { pushRouter } = useProgressRouter()

  // input search同步数据到filter
  const filterHandle = useCallback(({ suburbs, surrounding }) => {
    changeSuburbs(suburbs)
    toggleSurrounding(surrounding)
    showFilter()
  }, [showFilter, changeSuburbs, toggleSurrounding])

  // 上次是否打开了地图
  const [showMapLocal] = useLocalStorage(HOUSE_LIST_SHOW_MAP_KEY, false)
  // show properties点击事件
  const confrimHandle = useCallback(({
    suburbs,
    surrounding,
    description,
    searchType,
    routerQuery,
    priceFrom,
    priceTo,
    bedroom,
    bathroom,
    carspaces,
    propertyType
  }) => {
    const st = searchType || filterSearchType
    const isEstimate = isEqual(type, RESIDENTIAL_TABS[1]) || st.alias === RESIDENTIAL_ALIAS.estimate

    let slug
    if (isEstimate) {
      const estimateRoute = getEstimateRoute()
      estimateRoute.setSuburbs(suburbs)
      estimateRoute.pushNewZealand()
      slug = estimateRoute.toSlug()
      RecentViewManager.cacheEstimateByArea(
        EstimateByArea.fromSuburb(suburbs)
      )
    } else {
      const houseRoute = getHouseRoute()
      houseRoute.setSuburbs(suburbs)
      houseRoute.surround = surrounding
      houseRoute.description = description
      houseRoute['price-from'] = priceFrom
      houseRoute['price-to'] = priceTo
      houseRoute.bedroom = bedroom
      houseRoute.bathroom = bathroom
      houseRoute.carspaces = carspaces
      houseRoute['property-type'] = propertyType
      houseRoute.pushNewZealand()
      if (!isSoldSearchType(st)) {
        houseRoute.changeMapVisible(showMapLocal)
      }
      slug = houseRoute.toSlug()

      // 缓存最近浏览数据
      const cacheData = {
        suburbs,
        surrounding,
        priceFrom,
        priceTo,
        bedrooms: bedroom,
        bathrooms: bathroom,
        carspaces,
        propertyType,
        filter: routerQuery
      }
      switch (st.alias) {
        case RESIDENTIAL_ALIAS.residential:
          RecentViewManager.cacheBuyBySuburb(
            BuyBySuburb.fromData(cacheData)
          )
          break;
        case RESIDENTIAL_ALIAS.rent:
          RecentViewManager.cacheRentBySuburb(
            RentBySuburb.fromData(cacheData)
          )
          break;
        case RESIDENTIAL_ALIAS.sold:
          RecentViewManager.cacheSoldBySuburb(
            SoldBySuburb.fromData(cacheData)
          )
          break;
      }
    }

    const isSuburb = isSuburbSearchType(st)
    if (isSuburb) {
      const firstSuburb = first(suburbs)
      if (!firstSuburb || firstSuburb.level !== 'suburb') return
    }

    const route = isEstimate ? estimateMap(slug) : isSuburb ? getRouterHref(URLS.suburbDetail, { slug: get(first(suburbs), 'slug') }) : houseList(st.route, slug, { filter: routerQuery })
    cacheRecentActivity({ searchType: isEstimate ? RESIDENTIAL_FILTER.searchTypes[1] : st, route, suburbs, slug, filter: routerQuery })

    homeDataLayer.searchClick(st)
    pushRouter(route)
    cacheSuggestedSuburbs(suburbs)
  }, [pushRouter, type, showMapLocal, filterSearchType])

  const fetcher = useCallback((keyword, searchType) => {
    return autoFetch(keyword, searchType)
  }, [])

  return (
    <>
      <SearchWrapNew title={title} type={type} setType={setType}>
        <ResidentialSearchContent
          onSearch={confrimHandle}
          onFilter={filterHandle}
          suburbs={suburbs}
          surrounding={surrounding}
          type={type}
        ></ResidentialSearchContent>
      </SearchWrapNew>
      <HomeSearchFilter
        show={displayFilter}
        searchType={filterSearchType}
        suburbs={suburbs}
        surrounding={surrounding}
        setSuburbs={changeSuburbs}
        toggleSurrounding={toggleSurrounding}
        onClose={hideFilter}
        onConfirm={confrimHandle}
        filter={RESIDENTIAL_FILTER}
        fetcher={fetcher}
        useShowAll
      ></HomeSearchFilter>
    </>
  )
}


export function ResidentialSearchContent({
  onSearch,
  onFilter,
  suburbs,
  surrounding,
  type
}) {

  const { setSuburbs, toggleSurrounding, isSearchSuburb } = useHomeSearchContext()
  useEffect(() => setSuburbs(suburbs), [suburbs, setSuburbs])
  useEffect(() => toggleSurrounding(surrounding), [surrounding, toggleSurrounding])
  const fetcher = useMemo(() => {
    if (type.value === RESIDENTIAL_TABS[2].value) {
      return fetchRent
    }
    return fetchBuy
  }, [type])

  const [showInputPop, setShowInputPop] = useState(false)
  return (
    <>
      {
        !showInputPop && (
          <HomeSearchInputNew
            filterButton={get(type, 'showFilter', true)}
            onSearch={onSearch}
            onFilter={onFilter}
            mobileDisableInput
            showTypes
            onInputClick={() => setShowInputPop(true)}
          >
            <HomeSearchPopup fetcher={fetcher}></HomeSearchPopup>
          </HomeSearchInputNew>
        )
      }
      <Location useRegion={!isSearchSuburb} useDistrict={!isSearchSuburb}></Location>
      <SearchPopup
        show={showInputPop}
        onClose={() => setShowInputPop(false)}
        onSearch={onSearch}
        onFilter={onFilter}
      />
    </>
  )
}
